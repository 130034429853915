import _process from "process";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var process = _process;
!function (e, t) {
  exports = t();
}("undefined" != typeof self ? self : exports, () => (() => {
  var e = {
    75: function (e) {
      (function () {
        var t, n, r, o, a, s;
        "undefined" != typeof performance && null !== performance && performance.now ? e.exports = function () {
          return performance.now();
        } : "undefined" != typeof process && null !== process && process.hrtime ? (e.exports = function () {
          return (t() - a) / 1000000;
        }, n = process.hrtime, o = (t = function () {
          var e;
          return 1000000000 * (e = n())[0] + e[1];
        })(), s = 1000000000 * process.uptime(), a = o - s) : Date.now ? (e.exports = function () {
          return Date.now() - r;
        }, r = Date.now()) : (e.exports = function () {
          return new Date().getTime() - r;
        }, r = new Date().getTime());
      }).call(this || _global);
    },
    4087: (e, t, n) => {
      for (var r = n(75), o = "undefined" == typeof window ? n.g : window, a = ["moz", "webkit"], s = "AnimationFrame", i = o["request" + s], u = o["cancel" + s] || o["cancelRequest" + s], l = 0; !i && l < a.length; l++) i = o[a[l] + "Request" + s], u = o[a[l] + "Cancel" + s] || o[a[l] + "CancelRequest" + s];

      if (!i || !u) {
        var c = 0,
            p = 0,
            d = [];
        i = function (e) {
          if (0 === d.length) {
            var t = r(),
                n = Math.max(0, 16.666666666666668 - (t - c));
            c = n + t, setTimeout(function () {
              var e = d.slice(0);
              d.length = 0;

              for (var t = 0; t < e.length; t++) if (!e[t].cancelled) try {
                e[t].callback(c);
              } catch (e) {
                setTimeout(function () {
                  throw e;
                }, 0);
              }
            }, Math.round(n));
          }

          return d.push({
            handle: ++p,
            callback: e,
            cancelled: !1
          }), p;
        }, u = function (e) {
          for (var t = 0; t < d.length; t++) d[t].handle === e && (d[t].cancelled = !0);
        };
      }

      e.exports = function (e) {
        return i.call(o, e);
      }, e.exports.cancel = function () {
        u.apply(o, arguments);
      }, e.exports.polyfill = function (e) {
        e || (e = o), e.requestAnimationFrame = i, e.cancelAnimationFrame = u;
      };
    }
  },
      t = {};

  function n(r) {
    var o = t[r];
    if (void 0 !== o) return o.exports;
    var a = t[r] = {
      exports: {}
    };
    return e[r].call(a.exports, a, a.exports, n), a.exports;
  }

  n.n = e => {
    var t = e && e.__esModule ? () => e.default : () => e;
    return n.d(t, {
      a: t
    }), t;
  }, n.d = (e, t) => {
    for (var r in t) n.o(t, r) && !n.o(e, r) && Object.defineProperty(e, r, {
      enumerable: !0,
      get: t[r]
    });
  }, n.g = function () {
    if ("object" == typeof globalThis) return globalThis;

    try {
      return this || _global || new Function("return this")();
    } catch (e) {
      if ("object" == typeof window) return window;
    }
  }(), n.o = (e, t) => Object.prototype.hasOwnProperty.call(e, t);
  var r = {};
  return (() => {
    "use strict";

    n.d(r, {
      default: () => S
    });
    var e = n(4087),
        t = n.n(e);

    const o = function (e) {
      return new RegExp(/<[a-z][\s\S]*>/i).test(e);
    },
          a = function (e) {
      var t = document.createElement("div");
      return t.innerHTML = e, t.childNodes;
    },
          s = function (e, t) {
      return Math.floor(Math.random() * (t - e + 1)) + e;
    };

    var i = "TYPE_CHARACTER",
        u = "REMOVE_CHARACTER",
        l = "REMOVE_ALL",
        c = "REMOVE_LAST_VISIBLE_NODE",
        p = "PAUSE_FOR",
        d = "CALL_FUNCTION",
        f = "ADD_HTML_TAG_ELEMENT",
        v = "CHANGE_DELETE_SPEED",
        h = "CHANGE_DELAY",
        m = "CHANGE_CURSOR",
        y = "PASTE_STRING",
        g = "HTML_TAG";

    function E(e, t) {
      var n = Object.keys(e);

      if (Object.getOwnPropertySymbols) {
        var r = Object.getOwnPropertySymbols(e);
        t && (r = r.filter(function (t) {
          return Object.getOwnPropertyDescriptor(e, t).enumerable;
        })), n.push.apply(n, r);
      }

      return n;
    }

    function w(e) {
      for (var t = 1; t < arguments.length; t++) {
        var n = null != arguments[t] ? arguments[t] : {};
        t % 2 ? E(Object(n), !0).forEach(function (t) {
          N(e, t, n[t]);
        }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(n)) : E(Object(n)).forEach(function (t) {
          Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(n, t));
        });
      }

      return e;
    }

    function T(e) {
      return function (e) {
        if (Array.isArray(e)) return b(e);
      }(e) || function (e) {
        if ("undefined" != typeof Symbol && null != e[Symbol.iterator] || null != e["@@iterator"]) return Array.from(e);
      }(e) || function (e, t) {
        if (e) {
          if ("string" == typeof e) return b(e, t);
          var n = Object.prototype.toString.call(e).slice(8, -1);
          return "Object" === n && e.constructor && (n = e.constructor.name), "Map" === n || "Set" === n ? Array.from(e) : "Arguments" === n || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n) ? b(e, t) : void 0;
        }
      }(e) || function () {
        throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
      }();
    }

    function b(e, t) {
      (null == t || t > e.length) && (t = e.length);

      for (var n = 0, r = new Array(t); n < t; n++) r[n] = e[n];

      return r;
    }

    function A(e, t) {
      for (var n = 0; n < t.length; n++) {
        var r = t[n];
        r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
      }
    }

    function N(e, t, n) {
      return t in e ? Object.defineProperty(e, t, {
        value: n,
        enumerable: !0,
        configurable: !0,
        writable: !0
      }) : e[t] = n, e;
    }

    const S = function () {
      function n(r, E) {
        var b = this || _global;
        if (function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, n), N(this || _global, "state", {
          cursorAnimation: null,
          lastFrameTime: null,
          pauseUntil: null,
          eventQueue: [],
          eventLoop: null,
          eventLoopPaused: !1,
          reverseCalledEvents: [],
          calledEvents: [],
          visibleNodes: [],
          initialOptions: null,
          elements: {
            container: null,
            wrapper: document.createElement("span"),
            cursor: document.createElement("span")
          }
        }), N(this || _global, "options", {
          strings: null,
          cursor: "|",
          delay: "natural",
          pauseFor: 1500,
          deleteSpeed: "natural",
          loop: !1,
          autoStart: !1,
          devMode: !1,
          skipAddStyles: !1,
          wrapperClassName: "Typewriter__wrapper",
          cursorClassName: "Typewriter__cursor",
          stringSplitter: null,
          onCreateTextNode: null,
          onRemoveNode: null
        }), N(this || _global, "setupWrapperElement", function () {
          b.state.elements.container && (b.state.elements.wrapper.className = b.options.wrapperClassName, b.state.elements.cursor.className = b.options.cursorClassName, b.state.elements.cursor.innerHTML = b.options.cursor, b.state.elements.container.innerHTML = "", b.state.elements.container.appendChild(b.state.elements.wrapper), b.state.elements.container.appendChild(b.state.elements.cursor));
        }), N(this || _global, "start", function () {
          return b.state.eventLoopPaused = !1, b.runEventLoop(), b;
        }), N(this || _global, "pause", function () {
          return b.state.eventLoopPaused = !0, b;
        }), N(this || _global, "stop", function () {
          return b.state.eventLoop && ((0, e.cancel)(b.state.eventLoop), b.state.eventLoop = null), b;
        }), N(this || _global, "pauseFor", function (e) {
          return b.addEventToQueue(p, {
            ms: e
          }), b;
        }), N(this || _global, "typeOutAllStrings", function () {
          return "string" == typeof b.options.strings ? (b.typeString(b.options.strings).pauseFor(b.options.pauseFor), b) : (b.options.strings.forEach(function (e) {
            b.typeString(e).pauseFor(b.options.pauseFor).deleteAll(b.options.deleteSpeed);
          }), b);
        }), N(this || _global, "typeString", function (e) {
          var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null;
          if (o(e)) return b.typeOutHTMLString(e, t);

          if (e) {
            var n = b.options || {},
                r = n.stringSplitter,
                a = "function" == typeof r ? r(e) : e.split("");
            b.typeCharacters(a, t);
          }

          return b;
        }), N(this || _global, "pasteString", function (e) {
          var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null;
          return o(e) ? b.typeOutHTMLString(e, t, !0) : (e && b.addEventToQueue(y, {
            character: e,
            node: t
          }), b);
        }), N(this || _global, "typeOutHTMLString", function (e) {
          var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null,
              n = arguments.length > 2 ? arguments[2] : void 0,
              r = a(e);
          if (r.length > 0) for (var o = 0; o < r.length; o++) {
            var s = r[o],
                i = s.innerHTML;
            s && 3 !== s.nodeType ? (s.innerHTML = "", b.addEventToQueue(f, {
              node: s,
              parentNode: t
            }), n ? b.pasteString(i, s) : b.typeString(i, s)) : s.textContent && (n ? b.pasteString(s.textContent, t) : b.typeString(s.textContent, t));
          }
          return b;
        }), N(this || _global, "deleteAll", function () {
          var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "natural";
          return b.addEventToQueue(l, {
            speed: e
          }), b;
        }), N(this || _global, "changeDeleteSpeed", function (e) {
          if (!e) throw new Error("Must provide new delete speed");
          return b.addEventToQueue(v, {
            speed: e
          }), b;
        }), N(this || _global, "changeDelay", function (e) {
          if (!e) throw new Error("Must provide new delay");
          return b.addEventToQueue(h, {
            delay: e
          }), b;
        }), N(this || _global, "changeCursor", function (e) {
          if (!e) throw new Error("Must provide new cursor");
          return b.addEventToQueue(m, {
            cursor: e
          }), b;
        }), N(this || _global, "deleteChars", function (e) {
          if (!e) throw new Error("Must provide amount of characters to delete");

          for (var t = 0; t < e; t++) b.addEventToQueue(u);

          return b;
        }), N(this || _global, "callFunction", function (e, t) {
          if (!e || "function" != typeof e) throw new Error("Callbak must be a function");
          return b.addEventToQueue(d, {
            cb: e,
            thisArg: t
          }), b;
        }), N(this || _global, "typeCharacters", function (e) {
          var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null;
          if (!e || !Array.isArray(e)) throw new Error("Characters must be an array");
          return e.forEach(function (e) {
            b.addEventToQueue(i, {
              character: e,
              node: t
            });
          }), b;
        }), N(this || _global, "removeCharacters", function (e) {
          if (!e || !Array.isArray(e)) throw new Error("Characters must be an array");
          return e.forEach(function () {
            b.addEventToQueue(u);
          }), b;
        }), N(this || _global, "addEventToQueue", function (e, t) {
          var n = arguments.length > 2 && void 0 !== arguments[2] && arguments[2];
          return b.addEventToStateProperty(e, t, n, "eventQueue");
        }), N(this || _global, "addReverseCalledEvent", function (e, t) {
          var n = arguments.length > 2 && void 0 !== arguments[2] && arguments[2],
              r = b.options.loop;
          return r ? b.addEventToStateProperty(e, t, n, "reverseCalledEvents") : b;
        }), N(this || _global, "addEventToStateProperty", function (e, t) {
          var n = arguments.length > 2 && void 0 !== arguments[2] && arguments[2],
              r = arguments.length > 3 ? arguments[3] : void 0,
              o = {
            eventName: e,
            eventArgs: t || {}
          };
          return b.state[r] = n ? [o].concat(T(b.state[r])) : [].concat(T(b.state[r]), [o]), b;
        }), N(this || _global, "runEventLoop", function () {
          b.state.lastFrameTime || (b.state.lastFrameTime = Date.now());
          var e = Date.now(),
              n = e - b.state.lastFrameTime;

          if (!b.state.eventQueue.length) {
            if (!b.options.loop) return;
            b.state.eventQueue = T(b.state.calledEvents), b.state.calledEvents = [], b.options = w({}, b.state.initialOptions);
          }

          if (b.state.eventLoop = t()(b.runEventLoop), !b.state.eventLoopPaused) {
            if (b.state.pauseUntil) {
              if (e < b.state.pauseUntil) return;
              b.state.pauseUntil = null;
            }

            var r,
                o = T(b.state.eventQueue),
                a = o.shift();

            if (!(n <= (r = a.eventName === c || a.eventName === u ? "natural" === b.options.deleteSpeed ? s(40, 80) : b.options.deleteSpeed : "natural" === b.options.delay ? s(120, 160) : b.options.delay))) {
              var E = a.eventName,
                  A = a.eventArgs;

              switch (b.logInDevMode({
                currentEvent: a,
                state: b.state,
                delay: r
              }), E) {
                case y:
                case i:
                  var N = A.character,
                      S = A.node,
                      C = document.createTextNode(N),
                      _ = C;
                  b.options.onCreateTextNode && "function" == typeof b.options.onCreateTextNode && (_ = b.options.onCreateTextNode(N, C)), _ && (S ? S.appendChild(_) : b.state.elements.wrapper.appendChild(_)), b.state.visibleNodes = [].concat(T(b.state.visibleNodes), [{
                    type: "TEXT_NODE",
                    character: N,
                    node: _
                  }]);
                  break;

                case u:
                  o.unshift({
                    eventName: c,
                    eventArgs: {
                      removingCharacterNode: !0
                    }
                  });
                  break;

                case p:
                  var O = a.eventArgs.ms;
                  b.state.pauseUntil = Date.now() + parseInt(O);
                  break;

                case d:
                  var L = a.eventArgs,
                      D = L.cb,
                      M = L.thisArg;
                  D.call(M, {
                    elements: b.state.elements
                  });
                  break;

                case f:
                  var x = a.eventArgs,
                      P = x.node,
                      j = x.parentNode;
                  j ? j.appendChild(P) : b.state.elements.wrapper.appendChild(P), b.state.visibleNodes = [].concat(T(b.state.visibleNodes), [{
                    type: g,
                    node: P,
                    parentNode: j || b.state.elements.wrapper
                  }]);
                  break;

                case l:
                  var R = b.state.visibleNodes,
                      k = A.speed,
                      Q = [];
                  k && Q.push({
                    eventName: v,
                    eventArgs: {
                      speed: k,
                      temp: !0
                    }
                  });

                  for (var F = 0, H = R.length; F < H; F++) Q.push({
                    eventName: c,
                    eventArgs: {
                      removingCharacterNode: !1
                    }
                  });

                  k && Q.push({
                    eventName: v,
                    eventArgs: {
                      speed: b.options.deleteSpeed,
                      temp: !0
                    }
                  }), o.unshift.apply(o, Q);
                  break;

                case c:
                  var I = a.eventArgs.removingCharacterNode;

                  if (b.state.visibleNodes.length) {
                    var U = b.state.visibleNodes.pop(),
                        q = U.type,
                        G = U.node,
                        Y = U.character;
                    b.options.onRemoveNode && "function" == typeof b.options.onRemoveNode && b.options.onRemoveNode({
                      node: G,
                      character: Y
                    }), G && G.parentNode.removeChild(G), q === g && I && o.unshift({
                      eventName: c,
                      eventArgs: {}
                    });
                  }

                  break;

                case v:
                  b.options.deleteSpeed = a.eventArgs.speed;
                  break;

                case h:
                  b.options.delay = a.eventArgs.delay;
                  break;

                case m:
                  b.options.cursor = a.eventArgs.cursor, b.state.elements.cursor.innerHTML = a.eventArgs.cursor;
              }

              b.options.loop && (a.eventName === c || a.eventArgs && a.eventArgs.temp || (b.state.calledEvents = [].concat(T(b.state.calledEvents), [a]))), b.state.eventQueue = o, b.state.lastFrameTime = e;
            }
          }
        }), r) if ("string" == typeof r) {
          var A = document.querySelector(r);
          if (!A) throw new Error("Could not find container element");
          (this || _global).state.elements.container = A;
        } else (this || _global).state.elements.container = r;
        E && ((this || _global).options = w(w({}, (this || _global).options), E)), (this || _global).state.initialOptions = w({}, (this || _global).options), this.init();
      }

      var r, E;
      return r = n, (E = [{
        key: "init",
        value: function () {
          var e, t;
          this.setupWrapperElement(), this.addEventToQueue(m, {
            cursor: (this || _global).options.cursor
          }, !0), this.addEventToQueue(l, null, !0), !window || window.___TYPEWRITER_JS_STYLES_ADDED___ || (this || _global).options.skipAddStyles || (e = ".Typewriter__cursor{-webkit-animation:Typewriter-cursor 1s infinite;animation:Typewriter-cursor 1s infinite;margin-left:1px}@-webkit-keyframes Typewriter-cursor{0%{opacity:0}50%{opacity:1}100%{opacity:0}}@keyframes Typewriter-cursor{0%{opacity:0}50%{opacity:1}100%{opacity:0}}", (t = document.createElement("style")).appendChild(document.createTextNode(e)), document.head.appendChild(t), window.___TYPEWRITER_JS_STYLES_ADDED___ = !0), !0 === (this || _global).options.autoStart && (this || _global).options.strings && this.typeOutAllStrings().start();
        }
      }, {
        key: "logInDevMode",
        value: function (e) {
          (this || _global).options.devMode && console.log(e);
        }
      }]) && A(r.prototype, E), Object.defineProperty(r, "prototype", {
        writable: !1
      }), n;
    }();
  })(), r.default;
})());
export default exports;
export const Typewriter = exports.Typewriter;